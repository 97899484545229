<template>
  <PageSection
    v-loading="isLoading"
    class="Billing"
    :page-tooltip="$t('pages.settings.billing.billing.sidebar_description')"
    :page-title="$t('pages.settings.billing.billing.headline')"
  >
    <div
      v-if="subscription.canceledAt"
      data-testid="PlanScheduledForCancel"
      class="message is-warning is-full-width"
    >
      <div class="message-body">
        <i18n path="pages.settings.billing.billing.scheduled_for_cancel">
          <span slot="date">{{ datetime(subscription.periodEndsAt) }}</span>
        </i18n>
      </div>
    </div>

    <div class="columns">
      <div class="column is-4">
        <BillingUsersStatsCard
          :subscription="subscription"
        />
      </div>

      <div class="column is-4">
        <BillingMonthlyPaymentCard
          :subscription="subscription"
        />
      </div>

      <div class="column is-4">
        <BillingCurrentPlanCard
          :organisation="firstOrganisation"
          :subscription="subscription"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <PlanCommitmentsLimitationsTable :limits="subscription.commitmentLimitations" />
      </div>
    </div>

    <div v-if="firstOrganisation.accountManager" class="columns">
      <div class="column">
        <div class="has-background-grey-lighter p-a-m">
          <Heading size="5" color="black">
            {{ $t('pages.settings.billing.billing.account_manager') }}
          </Heading>

          <div class="subtitle is-size-6">
            {{ $t('pages.settings.billing.billing.account_manager_descr') }}
          </div>

          <table class="table is-striped is-fullwidth is-transparent">
            <tr>
              <td>{{ firstOrganisation.accountManager.full_name }}</td>
              <td class="is-narrow">
                <a
                  class="has-text-primary"
                  :href="`mailto:${firstOrganisation.accountManager.email}`"
                >
                  {{ firstOrganisation.accountManager.email }}
                </a>
              </td>
              <td class="is-narrow">
                <a
                  v-if="firstOrganisation.accountManager.phone"
                  class="has-text-primary"
                  :href="`tel:${firstOrganisation.accountManager.phone}`"
                >
                  {{ firstOrganisation.accountManager.phone }}
                </a>
                <span v-else>{{ $t('general.not_available') }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <BillingInvoicesCard class="is-full-height" />
      </div>

      <div class="column">
        <BillingDetailsCard
          :user="currentUser"
          :card="activeCard"
          :subscription="subscription"
          :organisation-id="firstOrganisation.id"
          class="is-full-height"
          @update="fetchUserOrganisationsRequest"
        />
      </div>
    </div>
  </PageSection>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Heading from '@hypefactors/shared/js/components/core/Heading.vue'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin.js'

import BillingCurrentPlanCard from '@/components/billing/BillingCurrentPlanCard.vue'
import BillingMonthlyPaymentCard from '@/components/billing/BillingMonthlyPaymentCard.vue'
import BillingUsersStatsCard from '@/components/billing/BillingUsersStatsCard.vue'
import BillingDetailsCard from '@/components/billing/BillingDetailsCard.vue'
import PlanCommitmentsLimitationsTable from '@/components/billing/PlanCommitmentsLimitationsTable.vue'
import BillingInvoicesCard from '@/components/billing/BillingInvoicesCard.vue'
import PageSection from '@/components/core/PageSection.vue'

export default {
  components: {
    Heading,
    PageSection,
    BillingInvoicesCard,
    PlanCommitmentsLimitationsTable,
    BillingDetailsCard,
    BillingUsersStatsCard,
    BillingMonthlyPaymentCard,
    BillingCurrentPlanCard
  },

  mixins: [DatetimeMixin],

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapGetters(['firstOrganisation', 'activeCard', 'subscription', 'currentUser'])
  },

  methods: {
    ...mapActions(['fetchUserOrganisationsRequest'])
  }
}
</script>
