<template>
  <div
    class="BillingDetailsCard has-background-grey-lighter p-a-m"
    data-testid="BillingDetailsCard"
  >
    <heading
      size="5"
      color="black"
    >
      {{ $t('components.billing_details_card.billing_details') }}
    </heading>
    <table
      v-if="card"
      class="table is-transparent is-full-width"
    >
      <tr>
        <td>{{ $t('components.billing_details_card.card_holder') }}</td>
        <td>{{ card.name }}</td>
      </tr>
      <tr>
        <td>{{ $t('components.billing_details_card.payment_method') }}</td>
        <td>
          <div class="is-flex">
            <div class="is-expanded">
              {{ card.brand }} **** {{ card.last4 }} (( {{ card.expMonth }}/{{ card.expYear }} ))
            </div>
            <v-button
              class="is-unstyled has-text-primary"
              data-testid="AddCreditCardButton"
              @click="isCardEditModalVisible = true"
            >
              {{ $t('forms.edit') }}
            </v-button>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          {{ $t('components.billing_details_card.next_billing_date') }}
        </td>
        <td>
          {{ publicationDate(subscription.periodEndsAt) }}
        </td>
      </tr>
      <tr v-if="billingCountry">
        <td>{{ $t('components.billing_details_card.billing_country') }}</td>
        <td>{{ billingCountry.name }}</td>
      </tr>
    </table>
    <div v-else>
      {{ $t('components.billing_details_card.no_billing_details') }}<br>
      <v-button
        class="is-unstyled has-text-primary"
        data-testid="AddCreditCardButton"
        @click="isCardEditModalVisible = true"
      >
        {{ $t('forms.edit') }}
      </v-button>
    </div>
    <el-dialog :visible.sync="isCardEditModalVisible" class="el-dialog--no-title-bg">
      <billing-stripe-card-update
        v-if="isCardEditModalVisible"
        :card="card"
        :organisation-id="organisationId"
        :user="user"
        always-open
        @cancel="isCardEditModalVisible = false"
        @update="$emit('update')"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StripeCard } from '@hypefactors/shared/js/models/StripeCard'
import { Subscription } from '@hypefactors/shared/js/models/Subscription'
import BillingStripeCardUpdate from '@/components/billing/BillingStripeCardUpdate'

import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

/**
 * @module BillingDetailsCard
 */
export default {
  name: 'BillingDetailsCard',
  components: { BillingStripeCardUpdate },
  mixins: [PublicationDateMixin],
  props: {
    /** @type HF_StripeCard */
    card: {
      type: StripeCard,
      default: null
    },
    /** @type HF_Subscription */
    subscription: {
      type: Subscription,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isCardEditModalVisible: false
    }
  },

  computed: {
    ...mapGetters(['getCountryByIso2']),

    billingCountry () {
      if (!this.card.address.country) return null
      return this.getCountryByIso2(this.card.address.country)
    }
  }
}
</script>
