<template functional>
  <div
    v-bind="data.attrs"
    class="BillingCenteredCard has-background-grey-lighter has-text-centered p-a-m is-flex is-column is-full-height"
  >
    <heading size="4" color="black">
      {{ props.title }}
    </heading>
    <div class="m-b-a m-t-a p-b-m">
      <slot />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
/**
 * Generic centered card component
 * @module BillingCenteredCard
 */
export default {
  name: 'BillingCenteredCard',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
