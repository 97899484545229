<template>
  <billing-centered-card :title="$t('components.billing_users_stats_card.title')">
    <div class="columns is-gapless is-mobile">
      <div class="column has-text-centered">
        <div class="is-uppercase">
          {{ $t('components.billing_users_stats_card.full_access') }}
        </div>
        <div class="is-size-3 has-text-weight-semibold">
          {{ subscription.commitmentLimitations['create-users'].used }}
          / {{ subscription.commitmentLimitations['create-users'].max }}
        </div>
      </div>
      <div class="column has-text-centered">
        <div class="is-uppercase">
          {{ $t('components.billing_users_stats_card.mobile_app_access') }}
        </div>
        <!-- TODO: Set this dynamic from somewhere -->
        <div class="is-size-3 has-text-weight-semibold">
          0
        </div>
      </div>
    </div>
    <div slot="footer">
      <!--<router-link-->
      <!--:to="{ name: 'settings.billing.users' }"-->
      <!--class="button is-dark"-->
      <!--&gt;-->
      <!--{{ $t('components.billing_users_stats_card.manage_users') }}-->
      <!--</router-link>-->
    </div>
  </billing-centered-card>
</template>

<script>
import BillingCenteredCard from '@/components/billing/BillingCenteredCard'
import { Subscription } from '@hypefactors/shared/js/models/Subscription'

/**
 * @module BillingUsersStatsCard
 */
export default {
  name: 'BillingUsersStatsCard',
  components: { BillingCenteredCard },
  props: {
    /** @type HF_Subscription */
    subscription: {
      type: Subscription,
      required: true
    }
  }
}
</script>
