<template>
  <billing-centered-card
    :title="$t('components.billing_monthly_payment_card.title')"
    data-testid="BillingMonthlyPaymentCard"
  >
    <div
      class="title is-3"
      data-testid="currentPlanTitle"
    >
      <template v-if="subscription.commitmentAmount">
        <!-- keep the currency without transforming -->
        {{ formatMoney(amount, false, subscription.commitmentCurrency, subscription.commitmentCurrency) }}
      </template>
      <template v-else>
        {{ $t('components.billing.payment_plan.free') }}
      </template>
    </div>
    <div
      v-if="!subscription.isFreemium"
      slot="footer"
    >
      <!--<router-link-->
      <!--:to="{ name: 'settings.billing.users' }"-->
      <!--class="button is-dark is-wide"-->
      <!--&gt;-->
      <!--{{ $t('components.billing_monthly_payment_card.add_users') }}-->
      <!--</router-link>-->
    </div>
  </billing-centered-card>
</template>

<script>
import BillingCenteredCard from '@/components/billing/BillingCenteredCard'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'

/**
 * Renders the current plan.
 * @module BillingMonthlyPaymentCard
 */
export default {
  name: 'BillingMonthlyPaymentCard',
  components: { BillingCenteredCard },
  mixins: [FormatMoneyMixin],
  props: {
    /** @type HF_Subscription */
    subscription: {
      type: Object,
      required: true
    }
  },
  computed: {
    interval () {
      return this.subscription.commitmentInterval
    },
    installments () {
      return this.interval === 'month' ? 1 : 12
    },
    amount () {
      return this.subscription.commitmentAmount / this.installments
    }
  }
}
</script>
