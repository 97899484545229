<template>
  <billing-centered-card :title="$t('components.billing_current_plan_card.title')">
    <div
      :class="{ 'm-b-none': !subscription.isFreemium }"
      class="title is-3"
      data-testid="currentPlanTitle"
    >
      {{ subscription.planName }}
    </div>
    <div slot="footer">
      <router-link
        :to="{ name: 'settings.billing.plans' }"
        data-testid="changePlanButton"
        class="button is-primary is-wide"
      >
        {{ $t('components.billing.change_plan') }}
      </router-link>
    </div>
  </billing-centered-card>
</template>

<script>
import BillingCenteredCard from '@/components/billing/BillingCenteredCard'

/**
 * Renders a card with the current active plan
 * @module BillingCurrentPlanCard
 */
export default {
  name: 'BillingCurrentPlanCard',
  components: { BillingCenteredCard },

  props: {
    /** @type HF_Subscription */
    subscription: {
      type: Object,
      required: true
    }
  }
}
</script>
