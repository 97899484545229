<template>
  <div class="BillingInvoicesCard has-background-grey-lighter p-a-m">
    <heading color="black" size="5">
      Paid Invoice
    </heading>
    <div v-if="lastInvoice">
      <table class="table is-transparent is-full-width">
        <tr>
          <td>{{ $t('components.billing_invoices_card.amount') }}</td>
          <td>{{ formatMoney(lastInvoice.total, true, undefined, lastInvoice.currency) }}</td>
        </tr>
        <tr>
          <td>
            {{ $t('components.billing_invoices_card.billing_date') }}
          </td>
          <td>
            {{ publicationDate(lastInvoice.date) }}
          </td>
        </tr>
      </table>
      <router-link
        :to="{ name: 'settings.billing.invoices' }"
        class="has-text-primary"
      >
        {{ $t('components.billing_invoices_card.see_all_invoices') }}
      </router-link>
    </div>
    <div v-else>
      {{ $t('components.billing_invoices_card.no_invoice') }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

/**
 * @module BillingInvoicesCard
 */
export default {
  name: 'BillingInvoicesCard',

  mixins: [FormatMoneyMixin, PublicationDateMixin],

  computed: {
    ...mapState({
      invoices: state => state.auth.invoices
    }),
    /** @type HF_Invoice */
    lastInvoice () {
      return this.invoices.length && this.invoices[0]
    }
  },

  mounted () {
    if (!this.invoices.length) {
      this.fetchInvoices()
    }
  },

  methods: {
    ...mapActions(['fetchInvoices'])
  }
}
</script>
