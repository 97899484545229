<template>
  <div class="PlanCommitmentsLimitationsTable has-background-grey-lighter p-a-m">
    <heading size="5" color="black">
      {{ $t('components.billing.plan_commitments_limitations_table.account_stats') }}
    </heading>
    <table class="table is-striped is-fullwidth is-transparent">
      <tbody>
        <plan-commitments-limitations-row
          v-for="(limit, slug) in limits"
          :key="slug"
          :limit="limit"
          :slug="slug"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import PlanCommitmentsLimitationsRow from '@/components/billing/PlanCommitmentsLimitationsRow'

/**
 * @module PlanCommitmentsLimitationsTable
 */
export default {
  name: 'PlanCommitmentsLimitationsTable',
  components: { PlanCommitmentsLimitationsRow },
  props: {
    /** @type {{HF_CommitmentLimitation}} */
    limits: {
      type: Object,
      required: true
    }
  }
}
</script>
