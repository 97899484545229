<template>
  <tr class="PlanCommitmentsLimitationsRow">
    <td>{{ limit.name }}</td>
    <td :class="{ 'has-text-danger': limit.max > 0 && limit.used >= limit.max }">
      <i v-if="limit.max === -1">{{ $t('components.billing.plan_commitments_limitations_row.unlimited') }}</i>
      <i v-else-if="limit.max === 0">{{ $t('components.billing.plan_commitments_limitations_row.upgrade') }}</i>
      <div v-else>
        {{ limit.used }} / <strong>{{ limit.max }}</strong>
      </div>
    </td>
  </tr>
</template>

<script>
/**
 * @module PlanCommitmentsLimitationsRow
 */
export default {
  name: 'PlanCommitmentsLimitationsRow',
  props: {
    /** @type HF_CommitmentLimitation */
    limit: {
      type: Object,
      required: true
    },
    slug: {
      type: String,
      required: true
    }
  }
}
</script>
